

























import constants from '@/constants'

import BnglInput from '@/components/shared/bngl-input.vue'
import UnitSelect from '@/components/shared/unit-select.vue'

export default {
  name: 'parameter-form',
  props: ['value'],
  components: {
    'bngl-input': BnglInput,
    'unit-select': UnitSelect,
  },
  data() {
    return {
      constants,
      parameter: { ...this.value },
    }
  },
  methods: {
    onParameterChange() {
      this.parameter.valid = !!this.parameter.name.trim() && !!this.parameter.definition
      this.$emit('input', this.parameter)
    },
    onDefinitionInputTab() {
      this.$refs.unitSelect.focus()
    },
    onSubmit() {
      this.$emit('on-submit')
    },
    focus() {
      this.$refs.nameInput.focus()
    },
    refresh() {
      this.$refs.definitionInput.refresh()
    },
  },
  watch: {
    value() {
      this.parameter = { ...this.value }
    },
  },
}
