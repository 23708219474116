



























































import { mapState } from 'vuex'
import get from 'lodash/get'
import { get as getr, post, patch, del } from '@/services/api'
import { Parameter } from '@/types'
import { AxiosResponse } from 'axios'

import bus from '@/services/event-bus'

import BnglText from '@/components/shared/bngl-text.vue'
import Split from '@/components/split.vue'
import ParameterForm from '@/components/shared/entities/parameter-form.vue'
import ParameterProperties from '@/components/model-page/secondary-view/parameter-properties.vue'

import findUniqName from '@/tools/find-uniq-name'
import objStrSearchFilter from '@/tools/obj-str-search-filter'
import blockHeightWoPadding from '@/tools/block-height-wo-padding'

const defaultParameter = {
  id: undefined,
  valid: false,
  name: '',
  definition: '',
  unit: null,
  annotation: '',
}

const searchProps = ['name', 'definition']

export default {
  name: 'parameters-component',
  components: {
    split: Split,
    'parameter-properties': ParameterProperties,
    'parameter-form': ParameterForm,
  },
  data() {
    return {
      error: false,
      deleteError: false,
      parameters: [],
      searchStr: '',
      tableHeight: null,
      newParameterModalVisible: false,
      currentParameter: { ...defaultParameter },
      columns: [
        {
          title: 'Name',
          key: 'name',
          maxWidth: 300,
        },
        {
          title: 'BioNetGen definition',
          render: (h, params) =>
            h(BnglText, {
              props: {
                entityType: 'parameter',
                value: params.row.definition,
              },
            }),
        },
        {
          title: 'Unit',
          render: (h, params) => h('span', get(params, 'row.unit.val'), ''),
          maxWidth: 80,
        },
        {
          title: 'Annotation',
          render: (h, params) => h('span', get(params, 'row.annotation', '').split('\n')[0]),
        },
      ],
    }
  },
  async created() {
    this.parameters = await this.getParameters()
  },
  mounted() {
    this.timeoutId = setTimeout(() => this.computeTableHeight(), 0)
    bus.$on('layoutChange', () => this.computeTableHeight())
  },
  beforeDestroy() {
    clearTimeout(this.timeoutId)
    bus.$off('layoutChange')
  },
  methods: {
    async getParameters() {
      const model = this.$store.state.model

      if (!model?.id) return []

      const res: AxiosResponse<Parameter[]> = await getr('parameters', {
        user_id: model?.user_id,
        model_id: model?.id,
      })

      return res.data
    },
    addParameter() {
      this.currentParameter = {
        ...defaultParameter,
        name: findUniqName(this.parameters, 'p'),
      }
      this.showNewParameterModal()

      this.$nextTick(() => {
        this.$refs.parameterForm.refresh()
        this.$refs.parameterForm.focus()
      })
    },
    async removeParameter() {
      const model = this.$store.state.model
      const res = await del<null>(`parameterss/${this.currentParameter.id}`)
      if (!res) {
        this.deleteError = true
        return
      }

      this.deleteError = false

      this.currentParameter = { ...defaultParameter }
      this.parameters = await this.getParameters()
    },
    onParameterSelect(parameter: Parameter) {
      this.currentParameter = parameter
    },
    async onOk() {
      this.error = false

      const model_id = this.$store.state.model?.id
      let res: AxiosResponse<Parameter> | undefined

      if (!this.currentParameter.id) res = await post<Parameter>('parameters', { ...this.currentParameter, model_id })
      else res = await patch<Parameter>(`parameters/${this.currentParameter.id}`, this.currentParameter)

      if (!res) {
        this.error = true
        return
      }

      this.hideNewParameterModal()

      this.parameters = await this.getParameters()
    },
    showNewParameterModal() {
      this.newParameterModalVisible = true
    },
    hideNewParameterModal() {
      this.error = false
      this.newParameterModalVisible = false
    },
    computeTableHeight() {
      this.tableHeight = blockHeightWoPadding(this.$refs.mainBlock)
    },
  },
  computed: {
    modelId() {
      return this.$store.state.model.id
    },
    filteredParameters() {
      return this.parameters.filter((e) => objStrSearchFilter(this.searchStr, e, { include: searchProps }))
    },
    emptyTableText() {
      return this.searchStr ? 'No matching parameters' : 'Create a parameter by using buttons below'
    },
  },
  watch: {
    async modelId() {
      this.parameters = await this.getParameters()
    },

    currentParameter() {
      this.error = false
      this.deleteError = false
    },
  },
}
