























import isEqualBy from '@/tools/is-equal-by'

import ParameterForm from '@/components/shared/entities/parameter-form.vue'

export default {
  name: 'parameter-properties',
  props: ['value', 'error'],
  components: {
    'parameter-form': ParameterForm,
  },
  data() {
    return {
      modifiedParam: this.value,
      oParam: this.value,
    }
  },
  mounted() {
    this.focusNameInput()
  },
  methods: {
    focusNameInput() {
      this.$nextTick(() => this.$refs.parameterForm.focus())
    },
    onInput() {
      this.$emit('input', this.modifiedParam)
    },
    applyParameterChange() {
      this.$emit('apply')
    },
    resetParameterChange() {
      this.modifiedParam = this.oParam
    },
  },
  computed: {
    parameterEdited() {
      return !isEqualBy(this.modifiedParam, this.oParam, ['name', 'definition', 'unit', 'annotation'])
    },
  },
  watch: {
    value(val, oldVal) {
      if (val.id !== oldVal.id) {
        this.modifiedParam = val
        this.oParam = val
        this.focusNameInput()
      }
    },
  },
}
